import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Divider, Link, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import {
    AccountTree,
    BugReport,
    Domain,
    Extension,
    Grading,
    Groups,
    Home,
    Hub,
    Label,
    Landscape,
    PhotoLibrary,
    ReceiptLong,
    SmartToy,
    ViewInAr,
    Widgets,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { FC } from 'react';

type AdminMenuProps = {
    toggleDrawer?: () => void;
};

const AdminMenu: FC<AdminMenuProps> = ({ toggleDrawer }) => {
    const { classes } = useStyles({ isMobile });
    const location = useLocation();

    return (
        <div className={classes.navContainer}>
            <MenuList className={classes.menuList}>
                <MenuItem className={classes.topItem} selected={location.pathname === '/'}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/'}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Dashboard</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link
                        onClick={toggleDrawer}
                        className={classes.link}
                        component={RouterLink}
                        target={'_blank'}
                        to={`https://${import.meta.env.PROD ? import.meta.env.VITE_URI : 'intract.dev'}/mission/horizon`}
                    >
                        <ListItemIcon>
                            <Hub />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Queues</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link
                        onClick={toggleDrawer}
                        className={classes.link}
                        component={RouterLink}
                        target={'_blank'}
                        to={`https://${
                            import.meta.env.PROD ? import.meta.env.VITE_URI : 'intract.dev'
                        }/mission/telescope`}
                    >
                        <ListItemIcon>
                            <BugReport />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Debugger</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <Divider component={'li'} variant={'middle'} />
                <MenuItem selected={location.pathname.includes('/users')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/users'}>
                        <ListItemIcon>
                            <Groups />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Users</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/organizations')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/organizations'}>
                        <ListItemIcon>
                            <Domain />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Organizations</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/projects')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/projects'}>
                        <ListItemIcon>
                            <AccountTree />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Projects</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/models')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/models'}>
                        <ListItemIcon>
                            <ViewInAr />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Models</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/collections')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/collections'}>
                        <ListItemIcon>
                            <Widgets />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Collections</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/reviews')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/reviews'}>
                        <ListItemIcon>
                            <Grading />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Reviews</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/user-models')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/user-models'}>
                        <ListItemIcon>
                            <ViewInAr />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>User Models</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/user-collections')}>
                    <Link
                        onClick={toggleDrawer}
                        className={classes.link}
                        component={RouterLink}
                        to={'/user-collections'}
                    >
                        <ListItemIcon>
                            <Widgets />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>User Collections</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/user-images')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/user-images'}>
                        <ListItemIcon>
                            <PhotoLibrary />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>User Images</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/ai-images')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/ai-images'}>
                        <ListItemIcon>
                            <SmartToy />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>A.I. Images</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/images')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/images'}>
                        <ListItemIcon>
                            <PhotoLibrary />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Public Images</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/tags')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/tags'}>
                        <ListItemIcon>
                            <Label />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Tags</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/hdris')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/hdris'}>
                        <ListItemIcon>
                            <Landscape />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>HDRIs</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/plugins')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/plugins'}>
                        <ListItemIcon>
                            <Extension />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Plugins</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
                <MenuItem selected={location.pathname.includes('/audits')}>
                    <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/audits'}>
                        <ListItemIcon>
                            <ReceiptLong />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Audit Logs</Typography>
                        </ListItemText>
                    </Link>
                </MenuItem>
            </MenuList>
        </div>
    );
};

const useStyles = makeStyles<{ isMobile: boolean }>()((theme) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    navContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
    menuList: {
        width: '100%',
        paddingTop: 0,
        '& .MuiMenuItem-root': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    topItem: {
        borderTopRightRadius: theme.spacing(1),
    },
}));

export default AdminMenu;
