import { combineReducers, configureStore, UnknownAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';
import apiSlice from './apiSlice';
import appSlice from './reducers/appSlice';
import authSlice, { logout } from './reducers/authSlice';
import { authMigrate, authVersion } from './migrations/authMigration';
import svgEditorSlice from './reducers/svgEditorSlice';
import { maintenanceMiddleware } from './middleware/maintenanceMiddleware';
import { userMigrate, userVersion } from './migrations/userMigration';
import { guestMigrate, guestVersion } from './migrations/guestMigration';
import userSlice from './reducers/userSlice';
import authMiddleware from './middleware/authMiddleware';
import guestSlice from '@/redux/reducers/guestSlice';
import { modelPortalMigrate, modelPortalVersion } from '@/redux/migrations/modelPortalMigration';
import modelPortalSlice from '@/redux/reducers/modelPortalSlice';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action): UnknownAction | null => {
        // Let's *not* send sensitive data to sentry, like passwords
        const exceptions = [
            'login',
            'resetPassword',
            'register',
            'updateUserPassword',
            'setUserPassword',
            'deleteUserAccount',
        ];
        if (exceptions.includes(action?.meta?.arg?.endpointName)) {
            return {
                ...action,
                meta: {
                    ...action.meta,
                    arg: {
                        ...action.meta.arg,
                        originalArgs: {
                            ...action.meta.arg.originalArgs,
                            ...(action.meta.arg.originalArgs.password && { password: '******' }),
                            ...(action.meta.arg.originalArgs.password_confirmation && {
                                password_confirmation: '******',
                            }),
                            ...(action.meta.arg.originalArgs.current_password && { current_password: '******' }),
                        },
                    },
                },
            };
        }
        return action;
    },
});

// const appPersistConfig = {
//     key: 'app',
//     storage: storage,
//     version: appVersion,
//     migrate: appMigrate,
// };

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    version: authVersion,
    migrate: authMigrate,
};

const guestPersistConfig = {
    key: 'guest',
    storage,
    version: guestVersion,
    migrate: guestMigrate,
};

const userPersistConfig = {
    key: 'user',
    storage,
    version: userVersion,
    migrate: userMigrate,
};

const modelPortalPersistConfig = {
    key: 'modelPortal',
    storage,
    version: modelPortalVersion,
    migrate: modelPortalMigrate,
};

const reducers = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    app: appSlice.reducer,
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    guest: persistReducer(guestPersistConfig, guestSlice.reducer),
    modelPortal: persistReducer(modelPortalPersistConfig, modelPortalSlice.reducer),
    svgs: svgEditorSlice.reducer,
    user: persistReducer(userPersistConfig, userSlice.reducer),
});

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
    if (logout.match(action)) {
        state = undefined;
    }

    return reducers(state, action);
};

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
export const store = configureStore({
    reducer: rootReducer,
    devTools: import.meta.env.VITE_DEPLOY_ENVIRONMENT !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat([apiSlice.middleware, authMiddleware.middleware, maintenanceMiddleware]),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
