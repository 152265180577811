import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import routes from '@/config/routes';
import defaultTheme from '@/config/theme';
import { useNavigate, useRoutes } from 'react-router-dom';
import usePageTracking from '@/hooks/usePageTracking';
import useTabSync from '@/hooks/useTabSync';
import EchoProvider from '@/contexts/EchoContext';
import 'react-activity/dist/Levels.css';
import 'react-activity/dist/Dots.css';
import 'react-activity/dist/Digital.css';
import 'react-activity/dist/Sentry.css';
import 'react-activity/dist/Windmill.css';
import 'react-activity/dist/Spinner.css';
import { toggleDarkMode } from '@/redux/reducers/userSlice';
import AppError from '@/errors/AppError';
import { ErrorBoundary } from 'react-error-boundary';
import AuthRouteHandler from '@/utils/AuthRouteHandler';
import ToolsProvider from '@/contexts/ToolsContext';
import { clearXSRF } from '@/redux/reducers/authSlice';
import { useGetUserQuery } from '@/redux/features/userApi';
import { useHeartbeatQuery } from '@/redux/features/authApi';
import WorkspaceProvider from '@/contexts/WorkspaceContext';

function App() {
    //Init Redux
    useHeartbeatQuery(undefined, { pollingInterval: 300000 });
    const dispatch = useAppDispatch();
    const maintenance = useAppSelector((state) => state.app.maintenance);

    const auth = useAppSelector((state) => state.auth);
    const user = useAppSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('auth', auth);
    }, [auth]);

    const { refetch, isUninitialized } = useGetUserQuery(null, {
        skip: (!auth.pkce.accessToken || !auth.xsrfToken) && !auth.status,
        refetchOnMountOrArgChange: true,
    });

    const { matchRoute } = AuthRouteHandler();

    usePageTracking();
    useTabSync();

    //Get OS Dark/Lite mode as initial default
    const osDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = user.settings.darkMode ?? osDarkMode;

    useEffect(() => {
        return () => {
            //Clear XSRF So it's not set for next session
            dispatch(clearXSRF());
        };
    }, []);

    useEffect(() => {
        dispatch(toggleDarkMode(prefersDarkMode));
    }, [prefersDarkMode]);

    let handleMaintenanceSet = useCallback(() => {
        !matchRoute && navigate('/maintenance');
    }, [matchRoute, navigate]);

    useEffect(() => {
        maintenance && handleMaintenanceSet();
        !maintenance && matchRoute && !isUninitialized && refetch();
    }, [handleMaintenanceSet, maintenance, matchRoute]);

    const configTheme = useCallback(() => {
        return defaultTheme(user.settings.darkMode ?? prefersDarkMode);
    }, [prefersDarkMode, user.settings.darkMode]);

    //Main Router
    const router = useRoutes(routes());

    return (
        <div
            id={'app'}
            data-testid={'appRoot'}
            style={{
                flex: 1,
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
            }}
        >
            <ToolsProvider>
                <EchoProvider>
                    <WorkspaceProvider>
                        <ThemeProvider theme={configTheme()}>
                            <ErrorBoundary FallbackComponent={AppError}>
                                <CssBaseline />
                                {router}
                            </ErrorBoundary>
                        </ThemeProvider>
                    </WorkspaceProvider>
                </EchoProvider>
            </ToolsProvider>
        </div>
    );
}

export default App;
