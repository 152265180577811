import { Button, IconButton, Link, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { toggleDarkMode } from '@/redux/reducers/userSlice';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

type GuestMenuProps = {

};

const GuestMenu: FC<GuestMenuProps> = () => {
    const user = useAppSelector((state) => state.user);
    const location = useLocation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const hideAuth = location.pathname.includes('/invite');
    const defaultColor = theme.palette.mode === 'dark' ? 'white' : 'black';
    const activeColor = theme.palette.primary.main;

    const toggleDarkModeHandler = () => {
        dispatch(toggleDarkMode());
    };

    return (
        <>
            {!hideAuth && (
                <>
                    <Link component={RouterLink} to={'/login'} data-testid={'login-link'}>
                        <Button>
                            <Typography
                                sx={{
                                    color: location.pathname === '/login' ? activeColor : defaultColor,
                                }}
                            >
                                Log In
                            </Typography>
                        </Button>
                    </Link>
                </>
            )}
            <IconButton
                sx={{ marginLeft: 0.5 }}
                size={'small'}
                onClick={toggleDarkModeHandler}
                color={'inherit'}
                aria-label={'System Color Preference'}
            >
                {user.settings.darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </IconButton>
        </>
    );
};

// const useStyles = makeStyles()((theme) => ({
//     link: {
//         color: theme.palette.text.primary,
//         textDecoration: 'none',
//         width: '100%',
//         display: 'flex',
//         alignItems: 'center',
//     },
//     navContainer: {
//         width: 250,
//         height: '100%',
//         borderTopRightRadius: theme.spacing(1),
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: theme.shadows[8],
//         overflowY: 'auto',
//         '&::-webkit-scrollbar': {
//             width: 0,
//             display: 'none',
//         },
//         scrollbarWidth: 'none',
//     },
//     menuList: {
//         paddingTop: 0,
//         '& .MuiMenuItem-root': {
//             paddingTop: theme.spacing(1),
//             paddingBottom: theme.spacing(1),
//         },
//     },
// }));

export default GuestMenu;
