import { lazy } from 'react';
import Admin from '@/screens/layouts/Admin';
import ErrorScreen from '@/screens/error/ErrorScreen';
import { RouteObject } from 'react-router-dom';
import Engine from '@/screens/layouts/Engine';
import Main from '@/screens/layouts/Main';

const AdminAllAiImagesScreen = lazy(() => import('@/screens/admin/AllAiImagesScreen'));
const AdminAllCollectionsScreen = lazy(() => import('@/screens/admin/AllCollectionsScreen'));
const AdminAllModelsScreen = lazy(() => import('@/screens/admin/AllModelsScreen'));
const AdminAllReviewsScreen = lazy(() => import('@/screens/admin/AllReviewsScreen'));
const AdminAllUserCollectionsScreen = lazy(() => import('@/screens/admin/AllUserCollectionsScreen'));
const AdminAllUserImagesScreen = lazy(() => import('@/screens/admin/AllUserImagesScreen'));
const AdminAllUserModelsScreen = lazy(() => import('@/screens/admin/AllUserModelsScreen'));
const AdminAssetScreen = lazy(() => import('@/screens/admin/AdminAssetScreen'));
const AdminAuditsScreen = lazy(() => import('@/screens/admin/AuditsScreen'));
const AdminDashboardScreen = lazy(() => import('@/screens/admin/DashboardScreen'));
const AdminHDRIsScreen = lazy(() => import('@/screens/admin/HDRIsScreen'));
const AdminImagesScreen = lazy(() => import('@/screens/admin/ImagesScreen'));
const AdminOrganizationScreen = lazy(() => import('@/screens/admin/OrganizationScreen'));
const AdminOrganizationsScreen = lazy(() => import('@/screens/admin/OrganizationsScreen'));
const AdminPluginsScreen = lazy(() => import('@/screens/admin/PluginsScreen'));
const AdminProjectCollectionScreen = lazy(() => import('@/screens/admin/ProjectCollectionScreen'));
const AdminProjectCollectionsScreen = lazy(() => import('@/screens/admin/ProjectCollectionsScreen'));
const AdminProjectModelsScreen = lazy(() => import('@/screens/admin/ProjectModelsScreen'));
const AdminProjectReviewScreen = lazy(() => import('@/screens/admin/ProjectReviewScreen'));
const AdminProjectReviewsScreen = lazy(() => import('@/screens/admin/ProjectReviewsScreen'));
const AdminProjectScreen = lazy(() => import('@/screens/admin/ProjectScreen'));
const AdminProjectsScreen = lazy(() => import('@/screens/admin/ProjectsScreen'));
const AdminTagsScreen = lazy(() => import('@/screens/admin/TagsScreen'));
const AdminUserAiImagesScreen = lazy(() => import('@/screens/admin/UserAiImagesScreen'));
const AdminUserCollectionScreen = lazy(() => import('@/screens/admin/UserCollectionScreen'));
const AdminUserCollectionsScreen = lazy(() => import('@/screens/admin/UserCollectionsScreen'));
const AdminUserImagesScreen = lazy(() => import('@/screens/admin/UserImagesScreen'));
const AdminUserModelsScreen = lazy(() => import('@/screens/admin/UserModelsScreen'));
const AdminUserScreen = lazy(() => import('@/screens/admin/UserScreen'));
const AdminUsersScreen = lazy(() => import('@/screens/admin/UsersScreen'));
const ContactScreen = lazy(() => import('@/screens/ContactScreen'));
const GoogleLoginCallbackScreen = lazy(() => import('@/screens/auth/GoogleLoginCallbackScreen'));
const LoginScreen = lazy(() => import('@/screens/auth/LoginScreen'));
const AuthCallback = lazy(() => import('@/screens/auth/AuthCallbackScreen'));
const MaintenanceScreen = lazy(() => import('@/screens/error/MaintenanceScreen'));

const routes = (): RouteObject[] => {
    return [
        //Main Routes
        {
            path: '/',
            children: [{ path: '/', element: <Admin /> }],
            loader: async (args, ctx) => {
                console.log('args', args, ctx);
                return true;
            },
        },
        //Non-Auth Routes - Hide sidebar so user can't try to leave settings if not verified/authorized
        {
            path: '/',
            element: <Main />,
            children: [
                {
                    path: '*',
                    element: (
                        <ErrorScreen
                            {...{
                                screenNotFound: true,
                                title: '404',
                                message: 'Unable to locate the page you requested.',
                            }}
                        />
                    ),
                },
                { path: 'maintenance', element: <MaintenanceScreen /> },
                { path: 'contact', element: <ContactScreen /> },
                { path: 'login', element: <LoginScreen /> },
                { path: 'auth/callback', element: <AuthCallback /> },
                { path: 'login/social/google/callback', element: <GoogleLoginCallbackScreen /> },
            ],
        },
        //Engine Routes
        {
            path: '/',
            element: <Engine />,
            children: [
                {
                    path: '/admin/assets/:assetType/:assetId/viewer',
                    element: <AdminAssetScreen />,
                },
            ],
        },
        //Admin Routes
        {
            path: '/',
            element: <Admin />,
            children: [
                { index: true, element: <AdminDashboardScreen /> },
                { path: '/users', element: <AdminUsersScreen /> },
                { path: '/organizations', element: <AdminOrganizationsScreen /> },
                { path: '/organizations/:orgId', element: <AdminOrganizationScreen /> },
                { path: '/projects', element: <AdminProjectsScreen /> },
                { path: '/projects/:projectId', element: <AdminProjectScreen /> },
                { path: '/projects/:projectId/models', element: <AdminProjectModelsScreen /> },
                { path: '/projects/:projectId/collections', element: <AdminProjectCollectionsScreen /> },
                {
                    path: '/projects/:projectId/collections/:collectionId',
                    element: <AdminProjectCollectionScreen />,
                },
                { path: '/projects/:projectId/reviews', element: <AdminProjectReviewsScreen /> },
                {
                    path: '/projects/:projectId/reviews/:reviewId',
                    element: <AdminProjectReviewScreen />,
                },
                { path: '/models', element: <AdminAllModelsScreen /> },
                { path: '/collections', element: <AdminAllCollectionsScreen /> },
                { path: '/reviews', element: <AdminAllReviewsScreen /> },
                { path: '/user-models', element: <AdminAllUserModelsScreen /> },
                { path: '/user-collections', element: <AdminAllUserCollectionsScreen /> },
                { path: '/user-images', element: <AdminAllUserImagesScreen /> },
                { path: '/ai-images', element: <AdminAllAiImagesScreen /> },
                { path: '/images', element: <AdminImagesScreen /> },
                { path: '/tags', element: <AdminTagsScreen /> },
                { path: '/hdris', element: <AdminHDRIsScreen /> },
                { path: '/plugins', element: <AdminPluginsScreen /> },
                { path: '/audits', element: <AdminAuditsScreen /> },
                {
                    path: '/users/:userId',
                    element: <AdminUserScreen />,
                },
                {
                    path: '/users/:userId/models',
                    element: <AdminUserModelsScreen />,
                },
                {
                    path: '/users/:userId/images',
                    element: <AdminUserImagesScreen />,
                },
                {
                    path: '/users/:userId/ai-images',
                    element: <AdminUserAiImagesScreen />,
                },
                {
                    path: '/users/:userId/collections',
                    element: <AdminUserCollectionsScreen />,
                },
                {
                    path: '/users/:userId/collections/:collectionId',
                    element: <AdminUserCollectionScreen />,
                },
            ],
        },
    ];
};

export default routes;
