import { Link as RouterLink } from 'react-router-dom';
import {
    Badge,
    Divider,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Skeleton,
    Typography,
} from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { useWorkspace } from '@/contexts/WorkspaceContext';
import OrganizationStorageBar from '@/components/Organizations/OrganizationStorageBar';
import { AccountBalance, Group, Home } from '@mui/icons-material';
import ProjectSidebar from '@/components/Layout/Sidebar/ProjectSidebar';
import { FC, useEffect, useState } from 'react';
import AddProject from '@/components/Projects/AddProject';

type OrganizationSidebarProps = {
    toggleDrawer?: () => void;
};

const OrganizationSidebar: FC<OrganizationSidebarProps> = ({ toggleDrawer }) => {
    const { organization, projects, refetchProjects, project, workspaceProjectId } = useWorkspace();
    const [expandedId, setExpandedId] = useState<string | undefined>(undefined);
    const { classes } = useStyles();
    const location = useLocation();
    const orgPath = `/organizations/${organization?.id}`;

    const toggleExpanded = (id: string) => {
        if (id === project?.id) {
            return;
        }

        setExpandedId((current) => (current === id ? undefined : id));
    };

    useEffect(() => {
        setExpandedId(undefined);
    }, [location.pathname]);

    return (
        <>
            {!organization ? (
                <>
                    <MenuList sx={{ paddingY: 0 }}>
                        <MenuItem sx={{ p: 0, mb: 0 }} disabled={true}>
                            <Skeleton
                                sx={{ borderTopRightRadius: (theme) => theme.spacing(1) }}
                                style={{ flexGrow: 1 }}
                                animation={'wave'}
                                variant={'rectangular'}
                                height={45}
                            />
                        </MenuItem>
                        {[...Array(2)].map((el, i) => (
                            <MenuItem sx={{ px: 0, pb: 0.1 }} key={i} disabled={true}>
                                <Skeleton
                                    style={{ flexGrow: 1 }}
                                    animation={'wave'}
                                    variant={'rectangular'}
                                    height={35}
                                />
                            </MenuItem>
                        ))}
                    </MenuList>
                    <Divider sx={{ mt: 1 }} variant={'middle'} />
                    <div className={classes.storage}>
                        <Skeleton style={{ flexGrow: 1 }} animation={'wave'} variant={'rectangular'} height={85} />
                    </div>
                </>
            ) : (
                <>
                    <MenuList className={classes.menuList}>
                        <MenuItem className={classes.topItem} selected={location.pathname === orgPath}>
                            <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={orgPath}>
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={'subtitle1'} noWrap={true}>
                                        {organization.name}
                                    </Typography>
                                </ListItemText>
                            </Link>
                        </MenuItem>
                        {organization.member?.organizationGates.owner && (
                            <MenuItem selected={location.pathname === `${orgPath}/billing`}>
                                <Link
                                    onClick={toggleDrawer}
                                    className={classes.link}
                                    component={RouterLink}
                                    to={`${orgPath}/billing`}
                                >
                                    <ListItemIcon>
                                        <AccountBalance />
                                    </ListItemIcon>
                                    <Badge
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        invisible={!organization.is_locked}
                                        color={'info'}
                                        variant={'dot'}
                                    >
                                        <ListItemText>
                                            <Typography variant={'subtitle1'}>Billing</Typography>
                                        </ListItemText>
                                    </Badge>
                                </Link>
                            </MenuItem>
                        )}
                        {((organization.supports_teams && organization.member?.organizationGates.admin) ||
                            (!organization.supports_teams && organization.member?.organizationGates.viewer)) && (
                            <MenuItem selected={location.pathname === `${orgPath}/members`}>
                                <Link
                                    onClick={toggleDrawer}
                                    className={classes.link}
                                    component={RouterLink}
                                    to={`${orgPath}/members`}
                                >
                                    <ListItemIcon>
                                        <Group />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant={'subtitle1'}>Members</Typography>
                                    </ListItemText>
                                </Link>
                            </MenuItem>
                        )}
                    </MenuList>
                    <Divider variant={'middle'} />
                    <div className={classes.storage}>
                        <OrganizationStorageBar {...{ organization, large: true }} />
                    </div>
                </>
            )}
            <Divider variant={'middle'} sx={{ mb: 1 }}>
                Projects
            </Divider>
            <MenuList className={classes.projectMenuList}>
                {projects
                    ? projects.map((currentProject) => (
                          <ProjectSidebar
                              key={currentProject.id}
                              {...{
                                  project: currentProject,
                                  member: organization?.member,
                                  forceOpen: currentProject.id === workspaceProjectId,
                                  toggleDrawer,
                                  expandedId,
                                  toggleExpanded,
                              }}
                          />
                      ))
                    : [...Array(3)].map((el, i) => (
                          <MenuItem sx={{ px: 0 }} key={i} disabled={true}>
                              <Skeleton
                                  style={{ flexGrow: 1 }}
                                  animation={'wave'}
                                  variant={'rectangular'}
                                  height={30}
                              />
                          </MenuItem>
                      ))}
                {organization && organization.member && organization.member.organizationGates.editor && (
                    <div className={classes.addProject}>
                        {projects && projects.length > 0 && <Divider variant={'middle'} sx={{ mb: 1 }} />}
                        <AddProject {...{ organization, fullSmallButton: true, refetchProjects }} />
                    </div>
                )}
            </MenuList>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    storage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    menuList: {
        paddingTop: 0,
        '& .MuiMenuItem-root': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    projectMenuList: {
        paddingTop: 0,
        paddingBottom: 0,
        overflowY: 'auto',
        transition: `max-height 300ms ease-in-out`,
        '&::-webkit-scrollbar': {
            width: 8,
        },
        scrollbarWidth: 'thin',
        minHeight: 250,
    },
    topItem: {
        borderTopRightRadius: theme.spacing(1),
    },
    addProject: {
        marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(0.5),
    },
}));

export default OrganizationSidebar;
