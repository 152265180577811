import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { persistor } from '@/redux/store';
import { useAppDispatch } from '@/redux/hooks';
import { useLogoutMutation } from '@/redux/features/authApi';
import { logout as userLogout } from '@/redux/reducers/userSlice';
import { logout as authLogout } from '@/redux/reducers/authSlice';
import apiSlice from '@/redux/apiSlice';
import { Spinner } from 'react-activity';
import { useNavigate } from 'react-router-dom';

const LogoutButton: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const [logoutTrigger, { isSuccess, isError }] = useLogoutMutation();

    const logoutHandler = () => {
        dispatch(userLogout());
        dispatch(authLogout());
        dispatch(apiSlice.util.resetApiState());
        persistor.purge();
        persistor.persist();
        navigate('/login');
    };

    const logout = () => {
        setLoggingOut(true);

        if (import.meta.env.VITE_AUTH_FLOW === 'pkce') {
            logoutHandler();

            return;
        }

        logoutTrigger();
    };

    useEffect(() => {
        if (isSuccess) {
            logoutHandler();
        } else if (isError) {
            setLoggingOut(false);
        }
    }, [isSuccess, isError]);

    return (
        <Button
            disabled={loggingOut}
            sx={{ py: 1.5 }}
            onClick={logout}
            size={'large'}
            fullWidth
            startIcon={loggingOut ? <Spinner /> : <Logout />}
        >
            Logout
        </Button>
    );
};

export default LogoutButton;
